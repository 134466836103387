export const OKTA_GROUPS = {
  STREAMING_ADMIN: 'Streaming Admin',
  STREAMING_CUSTOMER_SUPPORT: 'Streaming Customer Support',
  STREAMING_DEVELOPER: 'Streaming Developer',
  STREAMING_INFOSEC: 'Streaming Infosec',
  STREAMING_MEDIA_OPERATORS: 'Streaming Media Operators',
  STREAMING_POLICY_MANAGER: 'Streaming Policy Manager',
  STREAMING_PRODUCT_MANAGER: 'Streaming Product Manager',
  STREAMING_STREAMING_LOGGER: 'Streaming Logger',
  STREAMING_VIEWER: 'Streaming Viewer',
} as const;

export type OktaGroup = (typeof OKTA_GROUPS)[keyof typeof OKTA_GROUPS];

export const checkGroupAuth = (allowedGroups: OktaGroup[], userGroups: OktaGroup[]) => {
  if (allowedGroups.length === 0) return true;

  return allowedGroups.some((group) => userGroups.includes(group));
};

export const AUTH_GROUPS_AUDIT = [
  OKTA_GROUPS.STREAMING_ADMIN,
  OKTA_GROUPS.STREAMING_DEVELOPER,
  OKTA_GROUPS.STREAMING_MEDIA_OPERATORS,
];

export const AUTH_GROUPS_BTC_TOOLS = [
  OKTA_GROUPS.STREAMING_ADMIN,
  OKTA_GROUPS.STREAMING_DEVELOPER,
  OKTA_GROUPS.STREAMING_MEDIA_OPERATORS,
];

export const AUTH_GROUPS_ESNI = [OKTA_GROUPS.STREAMING_ADMIN, OKTA_GROUPS.STREAMING_POLICY_MANAGER];

export const AUTH_GROUPS_EXPERIENCE_OVERRIDE = [
  OKTA_GROUPS.STREAMING_ADMIN,
  OKTA_GROUPS.STREAMING_CUSTOMER_SUPPORT,
  OKTA_GROUPS.STREAMING_DEVELOPER,
  OKTA_GROUPS.STREAMING_PRODUCT_MANAGER,
];

export const AUTH_GROUPS_GAME_FEATURES = [
  OKTA_GROUPS.STREAMING_ADMIN,
  OKTA_GROUPS.STREAMING_DEVELOPER,
  OKTA_GROUPS.STREAMING_MEDIA_OPERATORS,
  OKTA_GROUPS.STREAMING_PRODUCT_MANAGER,
];

export const AUTH_GROUPS_IP_OVERRIDE = [
  OKTA_GROUPS.STREAMING_ADMIN,
  OKTA_GROUPS.STREAMING_CUSTOMER_SUPPORT,
  OKTA_GROUPS.STREAMING_PRODUCT_MANAGER,
];

export const AUTH_GROUPS_LOGGER = [
  OKTA_GROUPS.STREAMING_ADMIN,
  OKTA_GROUPS.STREAMING_PRODUCT_MANAGER,
  OKTA_GROUPS.STREAMING_MEDIA_OPERATORS,
  OKTA_GROUPS.STREAMING_STREAMING_LOGGER,
];

export const AUTH_GROUPS_MEDIA_VALIDATION = [
  OKTA_GROUPS.STREAMING_ADMIN,
  OKTA_GROUPS.STREAMING_DEVELOPER,
  OKTA_GROUPS.STREAMING_MEDIA_OPERATORS,
];

export const AUTH_GROUPS_MILESTONES = [
  OKTA_GROUPS.STREAMING_ADMIN,
  OKTA_GROUPS.STREAMING_DEVELOPER,
  OKTA_GROUPS.STREAMING_MEDIA_OPERATORS,
  OKTA_GROUPS.STREAMING_PRODUCT_MANAGER,
];

export const AUTH_GROUPS_PARTNER = [
  OKTA_GROUPS.STREAMING_ADMIN,
  OKTA_GROUPS.STREAMING_MEDIA_OPERATORS,
];
