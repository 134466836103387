import { gql } from '../types/generated/gql';

export const PIPELINE_CONFIG_FRAGMENT = gql(`
  fragment PipelineConfigFields on PipelineConfigInfo {
    activeSource
    altaChannelUrl
    backhaulDecoderHost
    backhaulDecoderServiceLabel
    cam4DecoderHost
    cam4DecoderServiceLabel
    cam4Src
    eviBackhaulSrc
    eviProgramSwitchSrc
    eviSdpUrlAudio
    eviSdpUrlVideo
    eviSwitchDst
    fronthaulDecoderHost
    fronthaulDecoderServiceLabel
    id
    lexiInstanceUrl
    partnerLockEnabled
    partnerLockExclusions
    pd01Dst
    pd02Dst
    pd03Dst
    postGameSrc
    preGameSrc
    primaryBackhaulSrc
    primaryFronthaulSrc
    programSwitchDst
    programSwitchSrc
    sdpUrlAncillary
    sdpUrlAudio
    sdpUrlVideo
    teamId
  }
`);
